import Select from "react-select";

import { useField } from "formik";

export default function QuickFormSelect({ options, ...props }) {
    const [field, meta, { setValue, setTouched }] = useField(props);

    const yesNo = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];

    switch (options) {
        case "yesNo":
            options = yesNo;
            break;
        case "none":
            options = [];
            break;
        case "student_types":
            options = [
                { label: "Choose an option", value: 0 },
                { label: "NSFAS UJ", value: 4 },
                { label: "NSFAS WITS", value: 5 },
                { label: "CJC", value: 6 },
                { label: "NSFAS TVET", value: 1 },
                { label: "Bursary", value: 2 },
                { label: "Private", value: 3 },
            ];
            break;
        case "gender":
            options = [
                { value: 1, label: "Male" },
                { value: 2, label: "Female" },
            ];
            break;
        default:
            options = options;
            break;
    }

    const onChange = ({ value }) => {
        setValue(value);

        if (props.getUnits) {
            props.getUnits(value);
        }
        if (props.getUnitTypes) {
            props.getUnitTypes(value);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "10px",
            padding: 0,
        }),
        container: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#e7ebe5",
        }),
    };

    return (
        <div className="flex flex-col sm:col-span-1">
            <label htmlFor={props.name} className="text-sm text-dark_background">
                {props.label}
            </label>
            <Select
                id={props.name}
                options={options}
                onChange={onChange}
                onBlur={setTouched}
                value={options.find((option) => option.value === field.value)}
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={customStyles}
                className="font-light py-1 text-sm w-30 focus:outline-none focus:ring-primary focus:border-primary border"
                isDisabled={props.disabled ? props.disabled : false}
            />
            {!!props.error && props.touched && <p className="text-red-400 text-sm font-light">{props.errors}</p>}
        </div>
    );
}
