import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory, Link, useParams } from "react-router-dom";

import { toast } from "react-toastify";
// import { doc } from "firebase/firestore";

import { openModal } from "../../store/modal/modalReducer";
import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import { getBooking, getFlaggedIds } from "../../store/booking/bookingActions";
import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import AppLoader from "../Helpers/AppLoader";
import { reach } from "yup";
import PhoneInput from "react-phone-number-input";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsSwitch from "../Forms/SettingsSwitch";

export default function GridSaveInformation({ setDoc, db, current_staff, setLoading, setIgnoreRefresh }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [units, setUnits] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const { current_booking, flagged_ids } = useSelector((state) => state.booking);
    const [institutions, SetInstitutions] = useState([]);
    const [campus, setCampus] = useState([]);
    // const { properties } = useSelector((state) => state.properties);
    const { settings } = useSelector((state) => state.settings);
    const [lookups, setLookups] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [properties, setProperties] = useState([]);
    const [flagLoading, setFlagLoading] = useState(false);

    const { type } = useParams();
    var url_string = window.location.href;
    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");

    // console.log("Current booking:", current_booking)

    useEffect(async () => {
        if (process.env.REACT_APP_COLOUR === "UC") {
            getInstitutions();
            getCampus();
        }
        if (current_booking.property_id) {
            getUnits(current_booking.property_id);
            getUnitTypes(current_booking.property_id);
        }

        if (settings && settings.brokers === 1) {
            getBrokerList();
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups`,
        })
            .then((response) => {
                setLookups(response.data);
            })
            .catch((error) => {
                alert("lookups failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });
    }, [setUnits]);

    async function getBrokerList() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}broker_list`,
        }).then((response) => {
            let list = [];
            list.push({ key: 0, value: 0, label: "None" });
            Object.keys(response.data).forEach((key) => {
                list.push({ key: response.data[key].id, value: response.data[key].id, label: response.data[key].name + " " + response.data[key].surname + " - " + response.data[key].email });
            });
            setBrokers(list);
        });
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    async function getInstitutions(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}institutions`,
        }).then((response) => {
            SetInstitutions(response.data);
        });
    }

    async function requestPhoto(id) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}bookings/${id}/request_photo`,
        }).then((response) => {
            toast(<NotificationToast title={"Photo Request"} message={response.data.message} />);
            console.log(response)
        })
        .catch((error) => {
            alert("Photo request failed.");
        });
    }

    async function getCampus(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}campus`,
        }).then((response) => {
            setCampus(response.data);
        });
    }
    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    function deleteChat(id) {
        if (current_staff?.status === "Admin") {
            if (window.confirm("Are you sure?")) {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}delete_user_perm/${id}`,
                    data: {
                        current_staff,
                    },
                })
                    .then((response) => {
                        if (response.data === "done") {
                            // window.location.reload();
                            window.location.replace(window.location.href.split("user_id")[0].slice(0, -1));
                        } else {
                            alert("error");
                        }
                    })
                    .catch((error) => {
                        alert("floors failed");
                    });
            }
        }
    }

    async function delinkBed() {
        setLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delink_user_bed`,
            data: {
                booking_id: current_booking.id,
                user: current_staff,
                move_location: 0,
            },
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .catch((error) => {
                alert("properties failed");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function delinkUnit() {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delink_user_unit`,
            data: {
                booking_id: current_booking.id,
                user: current_staff,
                move_location: 0,
            },
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .catch((error) => {
                alert("properties failed");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // console.log(current_booking)

    async function flagUser(id_number) {
        setFlagLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}flag_this_user`,
            data: {
                id_number: id_number,
                booking_id: current_booking.id,
                user_id: current_booking.user.id,
                user: current_staff,
            },
        })
            .then((response) => {
                dispatch(getFlaggedIds());
                dispatch(getBooking(current_booking.id));
            })
            .catch((error) => {
                alert("flagging failed");
            })
            .finally(() => {
                setFlagLoading(false);
            });
    }

    async function unFlagUser(id_number) {
        setFlagLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove_flaged_user`,
            data: {
                id_number: id_number,
                booking_id: current_booking.id,
                user_id: current_booking.user.id,
                user: current_staff,
            },
        })
            .then((response) => {
                dispatch(getFlaggedIds());
                dispatch(getBooking(current_booking.id));
            })
            .catch((error) => {
                alert("flagging failed");
            })
            .finally(() => {
                setFlagLoading(false);
            });
    }

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            {/* Start Information */}
            <Formik
                enableReinitialize
                initialValues={{
                    name: current_booking.user.name,
                    surname: current_booking.user.surname,
                    email: current_booking.user.email,
                    cell: "+" + current_booking.user.cell,
                    joint_application: current_booking.joint_application,
                    company_application: current_booking.company_application,
                    booking_reference: current_booking.booking_reference,
                    id_number: current_booking.user.profile ? current_booking.user.profile.id_number : "",
                    gender: current_booking.user.profile ? current_booking.user.profile.gender : "",
                    broker_id: current_booking.user.broker_id ? current_booking.user.broker_id : "",
                    message_broker: current_booking.user.message_broker ? current_booking.user.message_broker : "",

                    study_year: current_booking.user.profile ? current_booking.user.profile.study_year : "",
                    property_id: current_booking?.property_id ? current_booking.property_id : "",
                    unit_type: current_booking?.unit_type ? current_booking.unit_type.id : "",

                    institution_study: current_booking?.user?.profile?.institution_study ? current_booking.user.profile.institution_study : "",
                    student_type: current_booking.student_type ? current_booking.student_type : 0,
                }}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    //format cell
                    values.cell = values.cell.replace("+", "");

                    let submitData = {
                        name: values.name,
                        surname: values.surname,
                        email: values.email,
                        cell: values.cell,
                        booking: current_booking.id,
                        joint_application: values.joint_application,
                        company_application: values.company_application,
                        booking_reference: values.booking_reference,
                        id_number: values.id_number,
                        gender: values.gender,

                        institution_study: values.institution_study,
                        student_type: values.student_type,

                        study_year: values.study_year,
                        property_id: values.property_id,
                        unit_type: values.unit_type,

                        type: type,
                        tag_id: tag_id,
                        current_staff_id: current_staff.id,
                    };

                    if (settings && settings.brokers === 1 && current_booking.user.is_broker === 0) {
                        submitData.broker_id = values.broker_id;
                        submitData.message_broker = values.message_broker;
                    }

                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}quick_save/${current_booking.user.id}`,
                        data: submitData,
                    })
                        .then((resp) => {
                            if (resp.data != "success") {
                                toast(<NotificationToast title="Something went wrong" message={resp.data} type="error" />);
                            } else {
                                // setDoc(
                                //     doc(db, "Bookings", String(current_booking.id)),
                                //     {
                                //         name: values.name,
                                //         surname: values.surname,
                                //         email: values.email,
                                //         cell: values.cell,
                                //         joint_application: parseInt(values.joint_application),
                                //         company_application: parseInt(values.company_application),
                                //         booking_reference: values.booking_reference,
                                //         id_number: values.id_number,
                                //         gender: values.gender
                                //     },
                                //     { merge: true }
                                // );

                                setIgnoreRefresh(true);
                                dispatch(getBooking(current_booking.id));
                                // setIgnoreRefresh(false);
                                toast(<NotificationToast title="Success" message="Data has been updated sucessfully" />);
                            }

                            // Add the + back for form prettiness
                            values.cell = "+" + values.cell;
                        })
                        .catch((error) => {
                            console.log(error);
                            toast(<NotificationToast title="Something went wrong" message="" type="error" />);
                        });

                    setSubmitting(false);
                }}
            >
                {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} className="rounded-3xl">
                        {settings && settings.brokers === 1 && current_booking?.user?.is_broker === 1 ? (
                            <>
                                <p className="text-center text-xl">Broker Account</p>
                                <br />
                            </>
                        ) : (
                            ""
                        )}
                        {process.env.REACT_APP_COLOUR === "UC" ? (
                            <>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                                    <Field label={"Name"} name="name" as={QuickFormText} />
                                    <Field label={"Surname"} name="surname" as={QuickFormText} />
                                    {/* <Field label={"Cell"} name="cell" as={QuickFormText} prepend="+" /> */}
                                </div>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-1">
                                    <div>
                                        <label htmlFor="cell" className="text-sm text-dark_background">
                                            <span>Cell</span>
                                        </label>
                                        <div>
                                            <PhoneInput
                                                defaultCountry="ZA"
                                                international
                                                countryCallingCodeEditable={false}
                                                onChange={(val) => {
                                                    setFieldValue("cell", val);
                                                }}
                                                onBlur={setFieldTouched}
                                                name="cell"
                                                value={values.cell}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                                    <Field label={"Email Address"} name="email" as={QuickFormText} />
                                    <Field label={"Reference"} name="booking_reference" as={QuickFormText} disabled={true} />
                                    <Field label={"ID Number"} name="id_number" as={QuickFormText} />
                                    {process.env.REACT_APP_COLOUR === "UC" ? (
                                        <>
                                            <QuickFormSelect
                                                label="Gender"
                                                name="gender"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.gender}
                                                touched={touched.gender}
                                                error={errors.gender}
                                                options={"gender"}
                                            />
                                            {current_booking.bed_id > 0 && current_booking.unit.floor.gender != values.gender && (
                                                <h2 className="text-red-700 font-bold">Please note there is a difference between the chosen bed, and the gender of this user.</h2>
                                            )}
                                            <QuickFormSelect
                                                label="Funding Type"
                                                name="student_type"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.student_type}
                                                touched={touched.student_type}
                                                error={errors.student_type}
                                                options={"student_types"}
                                                disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                            />
                                            <QuickFormSelect
                                                label="Institution"
                                                name="institution_study"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.institution_study}
                                                touched={touched.institution_study}
                                                error={errors.institution_study}
                                                options={institutions}
                                                disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                            />
                                        </>
                                    ) : (
                                        <div>
                                            <QuickFormSelect
                                                label="Joint Application"
                                                name="joint_application"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.joint_application}
                                                touched={touched.joint_application}
                                                error={errors.joint_application}
                                                options={"yesNo"}
                                            />
                                            <QuickFormSelect
                                                label="Company Application"
                                                name="company_application"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.company_application}
                                                touched={touched.company_application}
                                                error={errors.company_application}
                                                options={"yesNo"}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="grid lg:grid-cols-6 md:lg:grid-cols-6 gap-1 mb-5 ">
                                    <SettingsSelect
                                        label="Study Year"
                                        name="study_year"
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        value={values.study_year}
                                        touched={touched.study_year}
                                        error={errors.study_year}
                                        options={lookups ? lookups[8] : null}
                                        disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                    />

                                    <SettingsSelect
                                        label="Property"
                                        name="property_id"
                                        getUnitTypes={getUnitTypes}
                                        onChange={setFieldValue}
                                        value={values.property_id}
                                        options={properties}
                                        disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                    />
                                    <SettingsSelect
                                        label="Unit Type"
                                        name="unit_type"
                                        onChange={setFieldValue}
                                        value={values.unit_type}
                                        options={unitTypes.length > 0 ? unitTypes : "none"}
                                        disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                                    <Field label={"Name"} name="name" as={QuickFormText} />
                                    <Field label={"Surname"} name="surname" as={QuickFormText} />
                                    {/* <Field label={"Cell"} name="cell" as={QuickFormText} prepend="+" /> */}
                                </div>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-1">
                                    <div>
                                        <label htmlFor="cell" className="text-sm text-dark_background">
                                            <span>Cell</span>
                                        </label>
                                        <div>
                                            <PhoneInput
                                                defaultCountry="ZA"
                                                international
                                                countryCallingCodeEditable={false}
                                                onChange={(val) => {
                                                    setFieldValue("cell", val);
                                                }}
                                                onBlur={setFieldTouched}
                                                name="cell"
                                                value={values.cell != "+null" ? values.cell : "+27"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                                    <Field label={"Email Address"} name="email" as={QuickFormText} />
                                    <Field label={"Reference"} name="booking_reference" as={QuickFormText} disabled={true} />
                                    {process.env.REACT_APP_COLOUR === "QL" ? (
                                        <>
                                            <QuickFormSelect
                                                label="Joint Application"
                                                name="joint_application"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.joint_application}
                                                touched={touched.joint_application}
                                                error={errors.joint_application}
                                                options={"yesNo"}
                                            />
                                            <QuickFormSelect
                                                label="Company Application"
                                                name="company_application"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.company_application}
                                                touched={touched.company_application}
                                                error={errors.company_application}
                                                options={"yesNo"}
                                            />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <Field label={"ID Number"} name="id_number" as={QuickFormText} />
                                </div>
                            </>
                        )}

                        {settings && settings.brokers === 1 && current_booking?.user?.is_broker === 0 ? (
                            <>
                                <SettingsSelect
                                    label="Broker"
                                    name="broker_id"
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    value={values.broker_id}
                                    touched={touched.broker_id}
                                    error={errors.broker_id}
                                    options={brokers}
                                />
                                <br />
                                <Field name="message_broker" as={SettingsSwitch} label="Message Broker" />
                                <br />
                            </>
                        ) : (
                            ""
                        )}

                        {!current_staff.building_manager ? (
                            <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={regular("save")} text="Update" style={{ width: "100%" }} />
                        ) : (
                            ""
                        )}
                    </Form>
                )}
            </Formik>
            <div className="border-t-2 border-white mt-2 py-2 w-auto">
                {!current_staff.building_manager ? (
                    <>
                        {settings && settings.brokers === 1 && current_booking?.user?.is_broker === 1 ? (
                            <>
                                <CustomButton
                                    text="Broker Applicants"
                                    styling="w-full mb-2"
                                    icon={regular("people-group")}
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modal_type: "ModalBrokerApplicants",
                                                modal_props: {
                                                    current_booking: current_booking,
                                                },
                                            })
                                        )
                                    }
                                />
                            </>
                        ) : (
                            ""
                        )}

                        <CustomButton
                            text="Merged Applicants"
                            styling="w-full mb-2"
                            icon={regular("user-gear")}
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modal_type: "ModalMergedApplicants",
                                        modal_props: {
                                            current_booking: current_booking,
                                        },
                                    })
                                )
                            }
                        />
                        <CustomButton
                            text="Attachments | View All"
                            styling="w-full"
                            icon={regular("paperclip")}
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modal_type: "SlideoverFiles",
                                        modal_props: { type: type, tag_id: tag_id },
                                    })
                                )
                            }
                        />
                        <CustomButton
                            text="View Applicant"
                            styling="w-full mt-2"
                            icon={regular("circle-user")}
                            onClick={() => history.push("/edit/" + current_booking.user.id + "/" + current_booking.id)}
                        />
                        <CustomButton
                            text="REQUEST PHOTO"
                            styling="w-full mt-2"
                            icon={regular("camera")}
                            onClick={() => requestPhoto(current_booking.id)}
                        />
                    </>
                ) : (
                    ""
                )}

                {process.env.REACT_APP_COLOUR === "QL" && (
                    <>
                        <div className="text-center mt-4">
                            <p>{current_booking?.property?.name}</p>
                            <p>
                                {current_booking?.waiting_unit_id > 0 ? current_booking?.waiting_unit?.name : current_booking?.unit?.name}{" "}
                                {current_booking?.waiting_unit_id > 0 ? " or similar. (on waitlist)" : ""}
                            </p>
                        </div>
                        {!current_staff.building_manager ? (
                            <CustomButton
                                text="Link Unit"
                                styling="w-full mt-2"
                                icon={regular("bed")}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalUnitAssignment",
                                            modal_props: {
                                                current_booking,
                                                type: type,
                                                tag_id: tag_id,
                                            },
                                        })
                                    )
                                }
                            />
                        ) : (
                            ""
                        )}
                        {(current_booking.waiting_unit_id > 0 || current_booking.unit_id > 0) && !current_staff.building_manager ? (
                            <CustomButton text="De-link Unit" styling="w-full mt-2" icon={regular("bed")} onClick={() => delinkUnit()} />
                        ) : (
                            ""
                        )}
                    </>
                )}

                {process.env.REACT_APP_COLOUR === "UC" && (
                    <>
                        <div className="text-center mt-4">
                            <p>{current_booking?.property?.name}</p>
                            <p>
                                {/* {current_booking?.unit?.name} {current_booking?.bed?.name} */}
                                {current_booking?.unit?.name} {current_booking?.waiting_bed_id > 0 ? current_booking?.waiting_bed?.name : current_booking?.bed?.name}{" "}
                                {current_booking?.waiting_bed_id > 0 ? " or similar. (on waitlist)" : ""}
                            </p>
                            <p>{current_booking?.unit_type?.name}</p>
                        </div>

                        {/* {console.log(current_booking?.rates[current_booking.rates.length - 1].signed_lease_at != "")} */}

                        {current_booking.student_type && current_booking?.user?.profile?.institution_study && !current_staff.building_manager ? (
                            <CustomButton
                                text="Bed Assignment"
                                styling="w-full mt-2"
                                icon={regular("bed")}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalBedAssignment",
                                            modal_props: {
                                                current_booking,
                                                type: type,
                                                tag_id: tag_id,
                                            },
                                        })
                                    )
                                }
                            />
                        ) : (
                            ""
                        )}

                        {/* current_booking?.rates[current_booking.rates.length - 1].signed_lease_at == "" */}

                        {/* {current_booking.bed_id > 0 && !current_staff.building_manager ? ( */}
                        {(current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za") &&
                        (current_booking.waiting_bed_id > 0 || current_booking.bed_id > 0) &&
                        !current_staff.building_manager ? (
                            <CustomButton text="De-link Bed" styling="w-full mt-2" icon={regular("bed")} onClick={() => delinkBed()} />
                        ) : (
                            ""
                        )}
                    </>
                )}

                {flagged_ids.length > 0 && flagged_ids.includes(current_booking?.user?.profile?.id_number) ? (
                    <>
                        <CustomButton
                            text="Remove Flag From User"
                            styling="w-full mt-2"
                            icon={regular("circle-xmark")}
                            onClick={() => unFlagUser(current_booking.user?.profile?.id_number)}
                            loading={flagLoading}
                            disabled={flagLoading}
                        />
                    </>
                ) : (
                    <>
                        <CustomButton
                            text="Flag User"
                            styling="w-full mt-2"
                            icon={regular("triangle-exclamation")}
                            onClick={() => flagUser(current_booking.user?.profile?.id_number)}
                            loading={flagLoading}
                            disabled={flagLoading}
                        />
                    </>
                )}

                {(current_staff.id == "1" ||
                    (process.env.REACT_APP_COLOUR === "UC" && (current_staff.id == "291" || current_staff.id == "23399")) ||
                    (process.env.REACT_APP_COLOUR === "QP" && (current_staff.id == "291" || current_staff.id == "297")) ||
                    (process.env.REACT_APP_COLOUR === "QL" && (current_staff.id == "291" || current_staff.id == "297"))) && (
                    <CustomButton text="Delete User" styling="w-full mt-2 bg-red-700" icon={regular("bed")} onClick={() => deleteChat(current_booking?.user?.id)} />
                )}
            </div>
        </div>
    );
}
