import { GET_SETTINGS_STARTED, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILED, UPDATED_SETTINGS, SAVING_ACCOUNT_ARREARS, UPDATED_ACCOUNT_ARREARS_SYSTEM_RULES, UPDATED_ACCOUNT_ARREARS_GUEST_RULES, SAVING_ACCOUNT_ARREARS_GUEST_RULES } from "./settingsConstants";
import axios from "axios";

export const getSettings = () => {
    return (dispatch) => {
        dispatch(getSettingsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}system_settings`,
        })
            .then((response) => {
                dispatch(getSettingsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getSettingsFailed(error.response));
            });
    };
};

const getSettingsStarted = () => {
    return {
        type: GET_SETTINGS_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getSettingsSuccess = (settings) => {
    return {
        type: GET_SETTINGS_SUCCESS,
        payload: {
            settings,
        },
    };
};

const getSettingsFailed = (error) => {
    return {
        type: GET_SETTINGS_FAILED,
        payload: {
            error,
        },
    };
};

export const updateSettings = (settings) => {
    return (dispatch) => {
        dispatch(getSettingsStarted()); // You can show loading state

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}system_settings`, // Endpoint to update settings
            data: settings,
        })
            .then((response) => {
                dispatch({
                    type: UPDATED_SETTINGS,
                    payload: response.data, // Assuming the API returns the updated settings
                });
            })
            .catch((error) => {
                dispatch(getSettingsFailed(error.response));
            });
    };
};

export const savingAccountArrears = (payload) => {
    return {
        type: SAVING_ACCOUNT_ARREARS,
        payload,
    };
};



export const updatedAccountArrears = (payload) => {
    return {
        type: UPDATED_ACCOUNT_ARREARS_SYSTEM_RULES,
        payload,
    };
};

export const savingGuests = (payload) => {
    return {
        type: SAVING_ACCOUNT_ARREARS_GUEST_RULES,
        payload,
    };
};


export const updatedGuests = (payload) => {
    console.log("Dispatching UPDATED_ACCOUNT_ARREARS_GUEST_RULES with payload:", payload);
    return {
        type: UPDATED_ACCOUNT_ARREARS_GUEST_RULES,
        payload,
    };
};
