import React, { useEffect } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";

export default function ProfilePicture({ id, size, styling }) {
    const { staff } = useSelector((state) => state.auth);

    if (staff?.length > 0) {
        var sender = staff.find((e) => e.id === parseInt(id));
    }

    if (!size) {
        size = 40;
    }

    var styling = styling ? styling : "";
    return (
        <div className={styling}>
            {sender ? (
                sender.profile_picture ? (
                    <div className="relative ">
                        <div className="absolute left-0 top-0 z-10 rounded-full text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                            <p className="mx-auto text-xs bg-white">{sender.name}</p>
                            <p className="mx-auto text-xs bg-white">{sender.surname}</p>
                            {/* <p className="mx-auto text-xs bg-white">{sender.last_seen ? sender.last_seen : "Never Seen"}</p> */}
                        </div>
                        <div className="relative">
                            <div className="flex flex-wrap content-center">
                                <img src={sender.profile_picture} className="rounded-full aspect-square object-cover" width={size} title="sender" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative ">
                        <div className="absolute left-0 top-0 z-10 bg-white rounded-full text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                            <p className="mx-auto text-xs bg-white">{sender.name}</p>
                            <p className="mx-auto text-xs bg-white">{sender.surname}</p>
                            {/* <p className="mx-auto text-xs bg-white">{sender.last_seen ? sender.last_seen : "Never Seen"}</p> */}
                        </div>
                        <div className="relative">
                            <div className="flex flex-wrap content-center">
                                <Avatar name={sender?.name?.charAt(0) + " " + sender.surname?.charAt(0)} size={size} round={true} />
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <img src="/images/profile.png" className="rounded-full aspect-square object-cover" width={size} />
            )}
        </div>
    );
}
