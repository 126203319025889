import { GET_SETTINGS_STARTED, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILED, UPDATED_SETTINGS, SAVING_ACCOUNT_ARREARS, UPDATED_ACCOUNT_ARREARS_SYSTEM_RULES, UPDATED_ACCOUNT_ARREARS_GUEST_RULES, SAVING_ACCOUNT_ARREARS_GUEST_RULES } from "./settingsConstants";

const initialState = {
    settings: {},
    loading: false,
    error: null,
};

export default function settingsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                settings: payload.settings.settings,
                years: payload.settings.years,
            };
        case GET_SETTINGS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
            case UPDATED_SETTINGS:
                return {
                    ...state,
                    loading: false,
                    settings: {
                        ...state.settings,
                        ...payload, // Ensure updated settings are merged correctly
                    },
                };
            
            case SAVING_ACCOUNT_ARREARS:
                return {
                    ...state,
                    systemRules: {
                        ...state.systemRules,
                        ...payload,
                    },
                };
            case UPDATED_ACCOUNT_ARREARS_SYSTEM_RULES:
                return {
                    ...state,
                    loading: false,
                    systemRules: {
                        ...state.systemRules,
                        ...payload,
                    },
                };
            case SAVING_ACCOUNT_ARREARS_GUEST_RULES:
                return {
                    ...state,
                    guestRules: {
                        ...state.guestRules,
                        ...payload,
                    },
                };
            case UPDATED_ACCOUNT_ARREARS_GUEST_RULES:
                return {
                    ...state,
                    loading: false,
                    guestRules: {
                        ...state.guestRules,
                        ...payload,
                    },
                };    
        default: {
            return state;
        }
    }
}
