import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

// import { doc } from "firebase/firestore";

import CustomButton from "../Helpers/CustomButton";
import axios from "axios";
import NotificationToast from "../Helpers/NotificationToast";
import { getBooking } from "../../store/booking/bookingActions";
import { toast } from "react-toastify";

export default function GridBiometrics({ setDoc, db }) {
    const [biometricsLoading, setBiometricsLoading] = useState(true);
    const [ignoreRefresh, setIgnoreRefresh] = useState(false);

    const { current_booking } = useSelector((state) => state.booking);
    const dispatch = useDispatch();

    useEffect(async () => {
        await getBiometricAccount();
    }, []);

    async function getBiometricAccount() {
        setBiometricsLoading(false);
    }

    async function createBiometricAccount(user_id) {
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}create_biometric_account`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Biometrics account created"} message={"Biometrics account created"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"Biometrics Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setBiometricsLoading(false);
            });
    }
    async function switchBiometricAccount(user_id) {
        setBiometricsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}switch_biometric_account`,
            data: {
                user_id: user_id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"Biometrics account switched"} message={"Biometrics account switched"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"Biometrics Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setBiometricsLoading(false);
            });
    }

    return (
        <>
            {current_booking?.user?.hik_user_id == null && (
                <>
                <p>
                    HikCental Person ID: <span className="font-bold">{current_booking?.user.hik_user_id}</span>
                </p>
                <p>
                    HikCental Backend User ID: <span className="font-bold">{current_booking?.user.hik_user_id}</span>
                </p>
             <br />
             
                <CustomButton
                    text="Create Biometrics Account"
                    styling="w-full mb-2"
                    icon={regular("plus")}
                    onClick={() => createBiometricAccount(current_booking?.user?.id)}
                    loading={biometricsLoading}
                />
                </> 
            )}

            <CustomButton
                text={current_booking?.user?.hik_user_id !== null ? "Disable biometric access" : "Enable Biometric Access"}
                styling="w-full mb-2"
                icon={regular("chart-network")}
                onClick={() => switchBiometricAccount(current_booking?.user?.id)}
                loading={biometricsLoading}
            />
        </>
    );
}
